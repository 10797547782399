import { isNullOrUnDef } from '@/utils/is';
import API from "@/api/httpApi";
import { useStore } from "vuex";
import { defineComponent, ref, reactive, watch, toRefs, computed, nextTick, unref } from 'vue';
export default defineComponent({
  name: 'basicTemplate',
  props: {
    // 分页是否换行
    isPageWrap: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'total, sizes,prev, pager, next, jumper'
    },
    height: {
      type: String,
      default: ''
    },
    isPage: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'small'
    },
    tableData: {
      type: Array,
      default: []
    },
    tableColumns: {
      type: Array,
      default: []
    },
    total: {
      type: Number,
      default: 0
    },
    jumpKey: {
      type: Number,
      default: null
    },
    pageSize: {
      type: Number,
      default: 50
    },
    currentPage: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    emptyText: {
      type: String,
      default: '暂无数据'
    },
    tableRowCls: {
      type: Function,
      default: null
    },
    tableRowStyle: {
      type: Function,
      default: null
    },
    tableHighlight: {
      type: Boolean,
      default: false
    },
    tableRowKey: {
      type: String,
      default: ''
    }
  },
  emits: ['size-change', 'current-change', 'tableRowClick', 'handleSelectionChange'],
  setup(props, {
    emit
  }) {
    const {
      state,
      dispatch
    } = useStore();
    const tableBox = ref(null);
    const refTable = ref(null);
    const pagination = reactive({
      tableHeight: 0,
      total: computed(() => props.total),
      currentPage: props.currentPage,
      pageSize: props.pageSize,
      loading: computed(() => props.loading)
    });
    watch(() => props.currentPage, value => {
      pagination.currentPage = value;
    });
    watch(() => props.pageSize, value => {
      pagination.pageSize = value;
    });
    const pageSizeChange = async size => {
      const params = {
        type: 1,
        userConf: `${size}`
      };
      const {
        code
      } = await API.saveOrUpdateUserConf(params);
      if (code == 0) {
        dispatch('getConfigData');
        setTimeout(() => {
          const table = document.getElementsByClassName('el-table__body')[0];
          table.scrollIntoView();
          emit('size-change', size);
        }, 300);
      }
    };
    const currentPageChange = currentPage => {
      pagination.currentPage = currentPage;
      const table = document.getElementsByClassName('el-table__body')[0];
      emit('current-change', currentPage);
      table.scrollIntoView();
    };
    // 高亮搜索的行
    const tableRowClassName = e => {
      if (props.tableRowCls) {
        const fn = props.tableRowCls;
        return fn(e);
      }
      if (props.jumpKey === null) return;
      if (e.rowIndex === props.jumpKey) {
        return 'warning-row';
      }
    };
    const tableRowStyle = e => {
      if (props.tableRowStyle) {
        const fn = props.tableRowStyle;
        return fn(e);
      }
    };
    // 表格点击行数
    const tableRowClick = e => {
      emit('tableRowClick', e);
    };
    // 表格勾选
    const handleSelectionChange = e => {
      emit('handleSelectionChange', e);
    };
    function setCurrentRow(row) {
      if (isNullOrUnDef(unref(refTable))) return;
      unref(refTable).setCurrentRow(row);
    }
    //初始化table高度
    const handleInit = () => {
      nextTick(() => {
        pagination.tableHeight = tableBox.value && tableBox.value.offsetHeight - 40;
      });
    };
    //重新布局table
    const doLayout = () => {
      nextTick(() => {
        unref(refTable).doLayout();
      });
    };
    //清空用户的选择
    const onClearSelect = () => {
      nextTick(() => {
        unref(refTable).clearSelection();
      });
    };
    handleInit();
    return {
      tableRowClick,
      tableRowClassName,
      tableRowStyle,
      handleSelectionChange,
      tableBox,
      ...toRefs(pagination),
      pageSizeChange,
      currentPageChange,
      handleInit,
      setCurrentRow,
      doLayout,
      refTable,
      onClearSelect
    };
  }
});