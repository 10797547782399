import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6130fff4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "basicTemplate"
};
const _hoisted_2 = {
  key: 0,
  class: "basicTemplate__filter"
};
const _hoisted_3 = {
  class: "basicTemplate__table",
  ref: "tableBox",
  "element-loading-text": "拼命加载中"
};
const _hoisted_4 = {
  key: 1,
  class: "basicTemplate__pager"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.showFilter ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "filter", {}, undefined, true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
    ref: "refTable",
    data: _ctx.tableData,
    stripe: "",
    size: _ctx.size,
    style: {
      "width": "calc(100% - 1px)"
    },
    "empty-text": _ctx.emptyText,
    height: _ctx.height || _ctx.tableHeight,
    "row-key": _ctx.tableRowKey,
    "highlight-current-row": _ctx.tableHighlight,
    "row-class-name": e => _ctx.tableRowClassName(e),
    "row-style": _ctx.tableRowStyle,
    onRowClick: _cache[0] || (_cache[0] = e => _ctx.tableRowClick(e)),
    onSelectionChange: _ctx.handleSelectionChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (item, index) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: index
      }, [item.hasOwnProperty('fixed') ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 0,
        fixed: item.fixed,
        type: item.type || '',
        label: item.title,
        prop: item.key,
        "header-align": item.align,
        align: item.align,
        width: item.width,
        sortable: item.sortable
      }, _createSlots({
        _: 2
      }, [item.slots ? {
        name: "default",
        fn: _withCtx(data => [_renderSlot(_ctx.$slots, item.slots.customRender, _normalizeProps(_guardReactiveProps(data)), undefined, true)]),
        key: "0"
      } : undefined]), 1032, ["fixed", "type", "label", "prop", "header-align", "align", "width", "sortable"])) : item.visible && item.width ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 1,
        type: item.type || '',
        label: item.title,
        prop: item.key,
        "header-align": item.align,
        align: item.align,
        width: item.width,
        sortable: item.sortable
      }, _createSlots({
        _: 2
      }, [item.slots ? {
        name: "default",
        fn: _withCtx(data => [_renderSlot(_ctx.$slots, item.slots.customRender, _normalizeProps(_guardReactiveProps(data)), undefined, true)]),
        key: "0"
      } : undefined]), 1032, ["type", "label", "prop", "header-align", "align", "width", "sortable"])) : item.visible && item.minwidth ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 2,
        label: item.title,
        prop: item.key,
        "header-align": item.align,
        align: item.align,
        "min-width": item.minwidth,
        sortable: item.sortable
      }, _createSlots({
        _: 2
      }, [item.slots ? {
        name: "default",
        fn: _withCtx(data => [_renderSlot(_ctx.$slots, item.slots.customRender, _normalizeProps(_guardReactiveProps(data)), undefined, true)]),
        key: "0"
      } : undefined]), 1032, ["label", "prop", "header-align", "align", "min-width", "sortable"])) : item.visible ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 3,
        label: item.title,
        prop: item.key,
        "header-align": item.align,
        align: item.align,
        sortable: item.sortable
      }, _createSlots({
        _: 2
      }, [item.slots ? {
        name: "default",
        fn: _withCtx(data => [_renderSlot(_ctx.$slots, item.slots.customRender, _normalizeProps(_guardReactiveProps(data)), undefined, true)]),
        key: "0"
      } : undefined]), 1032, ["label", "prop", "header-align", "align", "sortable"])) : _createCommentVNode("", true)], 64);
    }), 128))]),
    _: 3
  }, 8, ["data", "size", "empty-text", "height", "row-key", "highlight-current-row", "row-class-name", "row-style", "onSelectionChange"])), [[_directive_loading, _ctx.loading]])], 512), _ctx.isPage ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_pagination, {
    class: _normalizeClass(_ctx.isPageWrap ? 'page__conter' : ''),
    onSizeChange: _ctx.pageSizeChange,
    "page-sizes": [25, 50, 80, 100],
    pageSize: _ctx.pageSize,
    "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => _ctx.pageSize = $event),
    currentPage: _ctx.currentPage,
    "onUpdate:currentPage": _cache[2] || (_cache[2] = $event => _ctx.currentPage = $event),
    onCurrentChange: _ctx.currentPageChange,
    layout: _ctx.layout,
    total: _ctx.total
  }, null, 8, ["class", "onSizeChange", "pageSize", "currentPage", "onCurrentChange", "layout", "total"])])) : _createCommentVNode("", true)]);
}